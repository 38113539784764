<template>
  <StandardModal
    :id="id"
    v-model="shown"
    :modal-title="title"
    :on-cancel="onCancel"
    :should-show-overlay="isSubmitting"
    size="xl"
    centered
    scrollable
    @hide="onCancel"
    @shown="resetModal"
  >
    <div
      class="d-grid my-2"
      style="grid-template-columns: repeat(2, 1fr); column-gap: 1rem"
    >
      <b-form-group
        :label="translate({ path: 'ANALYSIS_STEPS.BASKET.BASKET_TICKER' })"
        label-cols="5"
        label-class="font-weight-bold pr-2"
        label-align="left"
      >
        <b-form-input
          v-model="form.reference"
          :state="tickerValidState"
          type="text"
          required
          :disabled="shouldDisableRebalanceField"
        />

        <b-form-invalid-feedback id="reference-feedback">
          {{ translate({ path: 'MODALS.BASKET.TICKER_BASE_WARNING', params: { code: tickerBase } }) }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label-cols="5"
        label-class="font-weight-bold pr-2"
        label-align="left"
      >
        <template #label>
          {{ translate({ path: 'ANALYSIS_STEPS.BASKET.RETURN_TYPE' }) }}<span class="text-danger">*</span>
        </template>
        <StandardFormDropdown menu-class="scroll-md w-100 shadow">
          <template #button-content>{{
            translate({ path: 'GLOBAL.RETURN_TYPE_CONSTANTS', item: form.returnType })
          }}</template>
          <b-dropdown-item
            v-for="returnTypeOption in returnTypes"
            :key="returnTypeOption"
            :active="form.returnType === returnTypeOption"
            :disabled="shouldDisableRebalanceField"
            @click.stop="form.returnType = returnTypeOption"
          >
            {{ translate({ path: 'GLOBAL.RETURN_TYPE_CONSTANTS', item: returnTypeOption }) }}
          </b-dropdown-item>
        </StandardFormDropdown>
      </b-form-group>
      <b-form-group
        label-cols="5"
        label-class="font-weight-bold pr-2"
        label-align="left"
      >
        <template #label>
          {{ translate({ path: 'ANALYSIS_STEPS.BASKET.BASKET_NOTIONAL' }) }}<span class="text-danger">*</span>
        </template>
        <!-- Basket notional is disable for submission and rebalance because triangulation on a flattened strategies list/table is not implemented yet -->
        <b-form-input
          v-model="form.portfolioValue"
          disabled
        />
      </b-form-group>
      <b-form-group
        label-cols="5"
        label-class="font-weight-bold pr-2"
        label-align="left"
      >
        <template #label>
          {{ translate({ path: 'ANALYSIS_STEPS.BASKET.BASKET_CURRENCY' }) }}<span class="text-danger">*</span>
        </template>
        <!-- Basket currency is disable for submission and rebalance because triangulation on a flattened strategies list/table is not implemented yet -->
        <StandardFormDropdown
          menu-class="scroll-md w-100 shadow"
          :disabled="true"
        >
          <template #button-content>
            {{ form.toCurrency }}
          </template>
          <b-dropdown-item
            v-for="option in currencies"
            :key="option"
            :active="form.toCurrency === option"
            @click.stop="form.toCurrency = option"
          >
            {{ option }}
          </b-dropdown-item>
        </StandardFormDropdown>
      </b-form-group>

      <b-form-group
        label-cols="5"
        label-class="font-weight-bold pr-2"
        label-align="left"
      >
        <template #label>
          {{ translate({ path: 'ANALYSIS_STEPS.BASKET.SELECTION_DATE' }) }}<span class="text-danger">*</span>
        </template>
        <b-input-group>
          <b-input-group-prepend class="w-80">
            <b-form-datepicker
              v-model="form.basketObservationDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              required
              :date-disabled-fn="observationDateDisabledFn"
            />
          </b-input-group-prepend>

          <b-input-group-append>
            <b-button
              class="ml-auto"
              size="md"
              variant="outline-primary"
              @click="form.basketObservationDate = ''"
            >
              {{ translate({ path: 'GLOBAL.RESET' }) }}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group
        label-cols="5"
        label-class="font-weight-bold pr-2"
        label-align="left"
      >
        <template #label>
          {{ translate({ path: 'ANALYSIS_STEPS.BASKET.IMPLEMENTATION_DATE' }) }}<span class="text-danger">*</span>
        </template>
        <b-input-group>
          <b-input-group-prepend class="w-80">
            <b-form-datepicker
              v-model="form.basketImplementationDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              required
              :date-disabled-fn="implementationDateDisabledFn"
              :state="isImplementationDateValid"
            />
          </b-input-group-prepend>

          <b-input-group-append>
            <b-button
              class="ml-auto"
              size="md"
              variant="outline-primary"
              @click="form.basketImplementationDate = ''"
            >
              {{ translate({ path: 'GLOBAL.RESET' }) }}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group
        label-cols="5"
        label-class="font-weight-bold"
        label-align="left"
      >
        <template #label>
          {{ translate({ path: 'ANALYSIS_STEPS.BASKET.CREATION_TYPE' }) }}<span class="text-danger">*</span>
        </template>
        <StandardFormDropdown menu-class="scroll-md w-100 shadow">
          <template #button-content>{{ form.originalMeasurementType }}</template>
          <b-dropdown-item
            v-for="option in originalMeasurementTypes"
            :key="option"
            :active="form.originalMeasurementType === option"
            @click.stop="form.originalMeasurementType = option"
          >
            {{ option }}
          </b-dropdown-item>
        </StandardFormDropdown>
      </b-form-group>
      <!-- WAA-11286 Hide the dividend treatment dropdown for now
      <b-form-group
        v-if="isEquityBasketPortfolio"
        label-cols="5"
        label-class="font-weight-bold pr-2"
        label-align="left"
      >
        <template #label>
          {{ translate({ path: 'ANALYSIS_STEPS.BASKET.DIVIDEND_TREATMENT' }) }}<span class="text-danger">*</span>
        </template>
        <StandardFormDropdown menu-class="scroll-md w-100 shadow">
          <template #button-content>{{
            translate({
              path: 'ANALYSIS_STEPS.BASKET.CASH_REINVESTMENT_TYPE',
              item: form.basketDividendTreatment,
            })
          }}</template>
          <b-dropdown-item
            v-for="basketDividendTreatmentOption in basketDividendTreatments"
            :key="basketDividendTreatmentOption"
            :active="form.basketDividendTreatment === basketDividendTreatmentOption"
            @click.stop="form.basketDividendTreatment = basketDividendTreatmentOption"
          >
            {{
              translate({
                path: 'ANALYSIS_STEPS.BASKET.CASH_REINVESTMENT_TYPE',
                item: basketDividendTreatmentOption,
              })
            }}
          </b-dropdown-item>
        </StandardFormDropdown>
      </b-form-group> -->
    </div>
    <div>
      <!-- TODO: WAA-7712 - Check why sticky header doesn't work  -->
      <b-table
        id="basket-detail-table"
        :fields="fields"
        :items="items"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-icon-left="false"
        sort-direction="desc"
        hover
        striped
        sticky-header
        class="mt-2"
      >
        <template #cell(oldUnit)="{ item }">
          <span>{{ numDecimalPoints(item.oldUnit) }}</span>
        </template>

        <template #cell(targetUnit)="{ item }">
          <span>{{ numDecimalPoints(item.targetUnit) }}</span>
        </template>

        <template #cell(oldWeight)="{ item }">
          <span>{{ numDecimalPoints(item.oldWeight) }}%</span>
        </template>

        <template #cell(targetWeight)="{ item }">
          <span>{{ numDecimalPoints(item.targetWeight) }}%</span>
        </template>

        <template #cell(weightChange)="{ item }">
          <span :class="getColorClass(item.weightChange)">
            {{ getChangePrefix(item.weightChange) }}{{ numDecimalPoints(item.weightChange) }}%
          </span>
        </template>

        <template #cell(unitChange)="{ item }">
          <span :class="getColorClass(item.unitChange)">
            {{ getChangePrefix(item.unitChange) }}{{ numDecimalPoints(item.unitChange) }}
          </span>
        </template>

        <template #cell(compliant)="{ item }">
          <div class="text-center">
            <Icon
              :icon="getIconToShow(item.compliant)"
              :color="getIconColorToShow(item.compliant)"
              class="pr-4"
            />
          </div>
        </template>
      </b-table>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between">
        <StandardButton
          variant="secondary"
          @click="onCancel"
        >
          {{ translate({ path: 'GLOBAL.CANCEL' }) }}
        </StandardButton>

        <div
          v-if="isImplementationDateValid === false"
          class="d-flex align-items-center text-danger"
        >
          {{ translate({ path: 'MODALS.BASKET.IMPLEMENTATION_DATE_WARNING' }) }}
        </div>

        <StandardButton
          id="submit-basket-button"
          variant="primary"
          :disabled="!isBasketValid"
          @click="shouldShowConfirmationModal = true"
        >
          {{ translate({ path: 'GLOBAL.SUBMIT' }) }}
        </StandardButton>
      </div>
    </template>
    <ConfirmationModal
      :prompt-title="translate({ path: 'CONFIRMATION_DIALOGS.COMPLETE_SUBMITTING_THE_BASKET' })"
      :prompt-subtitle="translate({ path: 'CONFIRMATION_DIALOGS.PLEASE_MAKE_SURE_ALL_CHANGES_ARE_FINALIZED' })"
      :value="shouldShowConfirmationModal"
      @confirm="submitBasket"
      @cancel="shouldShowConfirmationModal = false"
    />
  </StandardModal>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { set } from 'vue-demi';
import { useVModel } from '@vueuse/core';
import useTranslation from '@/composables/useTranslation';
import { ChainedFilter } from '@/composables/useFilter';
import { orderBy } from 'lodash';
import { IPortfolioTreeStrategy, isPortfolioTreeStrategy } from '@/types/IPortfolioTree';
import { Currency } from '@/constants/Currency';
import usePortfolioTree, { getBasketStatus } from '@/composables/usePortfolioTree';
import { MeasurementTypeConstants } from '@/constants/MeasurementTypeConstants';
import { BasketCommentTypeConstants } from '@/constants/BasketCommentTypeConstants';
import { CashReinvestmentTypeConstants } from '@/constants/CashReinvestmentTypeConstants';
import { ReturnTypeConstants } from '@/constants/ReturnTypeConstants';
import { ComplianceStatusStyleConfigMap } from '@/types/analytics/Basket';
import {
  useCalculatePortfolioTree,
  useSavePortfolioTree,
  useSubmitBasket,
} from '@/composables/queries/usePortfolioTreeData';
import { SaveChangesModalResponse, SaveChangesStore } from '@/store/modules/SaveChangesStore';
import { useToasts } from '@/composables/useToasts';
import ConfirmationModal from '@/views/modals/ConfirmationModal.vue';
import { useTriangulation } from '@/composables/useTriangulation';
import { useFormDraft } from '@/composables/useFormDraft';
import { flatten, isEquityBasketPortfolioFn } from '@/utils/portfolioTree';
import Icon from '@/components/standard-components/Icon.vue';
import { usePortfolioTreeDraftIdentifiers } from '@/composables/useCorrectIdentifier';
import { BvTableField } from 'bootstrap-vue';
import { numDecimalPoints } from '@/filters';
import { createDate, plusBusinessDays } from '@/utils/dateUtils';
import useUser from '@/composables/useUser';

interface BasketDetailsData {
  code: string;
  name: string;
  currency: string;
  compliant: BasketCommentTypeConstants | null;
  oldWeight: number;
  targetWeight: number;
  weightChange: number;
  oldUnit: number;
  targetUnit: number;
  unitChange: number;
}

const DEFAULT_BASKET_REFERENCE = '';
const DEFAULT_BASKET_CURRENCY = Currency.USD;
const DEFAULT_BASKET_NAME = '';
const DEFAULT_ORIGIN_MEASUREMENT_TYPE = MeasurementTypeConstants.WEIGHTS;
const DEFAULT_RETURN_TYPE = ReturnTypeConstants.PRICE_RETURN;
const DEFAULT_BASKET_DIVIDEND_TREATMENT = CashReinvestmentTypeConstants.DISTRIBUTE;

export default defineComponent({
  name: 'BasketDetailsModal',
  components: { Icon, ConfirmationModal },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: {
    input: (val: boolean) => typeof val === 'boolean',
  },
  setup(props, { emit }) {
    const { translate } = useTranslation();

    const { masterPortfolioTree, isMasterIndex } = usePortfolioTree();

    const { isBasketUndergoingWorkflow, isDraftBasket } = getBasketStatus(masterPortfolioTree);

    const isEquityBasketPortfolio = isEquityBasketPortfolioFn(masterPortfolioTree);

    const title = computed(() => {
      return `${
        isBasketUndergoingWorkflow.value
          ? translate({ path: 'MODALS.REBALANCE_BASKET.TITLE' })
          : translate({ path: 'MODALS.CREATE_BASKET.TITLE' })
      } - ${form.value.name}`;
    });

    const id = computed(() => {
      return isBasketUndergoingWorkflow.value ? 'rebalancing-basket-modal' : 'basket-creation-modal';
    });

    const shown = useVModel(props, 'value', emit, { passive: true, eventName: 'input' });

    const sortBy = ref('');
    const sortDesc = ref(true);

    const { DEFAULT_BASKET_NOTIONAL } = useTriangulation();

    const basketDetailsData = computed((): BasketDetailsData[] | [] => {
      if (!masterPortfolioTree.value) {
        return [];
      }

      const flattenedTree = flatten(masterPortfolioTree.value.portfolioTree);
      const flattenedStrategies = flattenedTree.filter((component) => isPortfolioTreeStrategy(component));
      return flattenedStrategies.map((strategy) => getStrategyDetail(strategy));
    });

    const getStrategyDetail = (strategy: IPortfolioTreeStrategy) => {
      const oldWeightForNewBasket = strategy.initialWeighting ?? 0;
      const oldUnitForNewBasket = strategy.initialUnit ?? 0;
      return {
        code: strategy.strategy?.code ?? '-',
        name: strategy.strategy?.longName ?? '-',
        currency: strategy.toCurrency ?? '-',
        compliant: strategy.basketComplianceStatus ?? null,
        oldWeight: oldWeightForNewBasket,
        targetWeight: strategy.weighting ?? 0,
        weightChange: (strategy.weighting ?? 0) - oldWeightForNewBasket,
        oldUnit: oldUnitForNewBasket,
        targetUnit: strategy.unit ?? 0,
        unitChange: (strategy.unit ?? 0) - oldUnitForNewBasket,
      };
    };

    const getColorClass = (weightChange: number) => {
      if (weightChange > 0) return 'text-info';
      if (weightChange < 0) return 'text-danger';

      return '';
    };

    type HeaderType = BvTableField & { key: string; colType?: 'text' | 'number' };

    const baseHeaders = computed((): HeaderType[] => [
      {
        label: translate({ path: 'MODALS.CREATE_BASKET.COLUMNS.CODE' }),
        key: 'code',
        colType: 'text',
      },
      {
        label: translate({ path: 'MODALS.CREATE_BASKET.COLUMNS.NAME' }),
        key: 'name',
        colType: 'text',
      },
      {
        label: translate({ path: 'MODALS.CREATE_BASKET.COLUMNS.CURRENCY' }),
        key: 'currency',
        colType: 'text',
      },
      ...(isEquityBasketPortfolio.value
        ? [
            {
              label: translate({ path: 'MODALS.CREATE_BASKET.COLUMNS.COMPLIANT' }),
              key: 'compliant',
            },
          ]
        : []),
    ]);

    const weightHeaders: HeaderType[] = [
      {
        label: translate({ path: 'MODALS.CREATE_BASKET.COLUMNS.OLD_WEIGHT' }),
        key: 'oldWeight',
        colType: 'number',
      },
      {
        label: translate({ path: 'MODALS.CREATE_BASKET.COLUMNS.TARGET_WEIGHT' }),
        key: 'targetWeight',
        colType: 'number',
      },
      {
        label: translate({ path: 'MODALS.CREATE_BASKET.COLUMNS.WEIGHT_CHANGE' }),
        key: 'weightChange',
        colType: 'number',
      },
    ];

    const unitHeaders: HeaderType[] = [
      {
        label: translate({ path: 'MODALS.CREATE_BASKET.COLUMNS.OLD_UNIT' }),
        key: 'oldUnit',
        colType: 'number',
      },
      {
        label: translate({ path: 'MODALS.CREATE_BASKET.COLUMNS.TARGET_UNIT' }),
        key: 'targetUnit',
        colType: 'number',
      },
      {
        label: translate({ path: 'MODALS.CREATE_BASKET.COLUMNS.UNIT_CHANGE' }),
        key: 'unitChange',
        colType: 'number',
      },
    ];

    const headers = computed(() => {
      return [
        ...baseHeaders.value,
        ...(form.value.originalMeasurementType === MeasurementTypeConstants.WEIGHTS ? weightHeaders : unitHeaders),
      ];
    });

    const fields = computed(() => {
      return headers.value.map((o) => ({
        ...o,
        sortable: true,
        thClass: `align-middle py-2`,
        tdClass: `align-middle text-ellipsis ${o.colType === 'number' ? 'text-right pr-2' : ''} ${
          o.colType === 'text' ? 'text-left' : ''
        } `,
      }));
    });

    const items = computed(() => {
      let rows = ChainedFilter.from(basketDetailsData.value).result();
      rows = orderBy(rows, ['code'], ['asc']);
      return rows;
    });

    const onCancel = () => {
      shown.value = false;
    };

    /**
     * Ticker should be disabled for master index and if a basket is in the process of being rebalanced
     */
    const shouldDisableRebalanceField = computed(() => isBasketUndergoingWorkflow.value || isMasterIndex.value);

    const basketDefaultSelectionDate = createDate().toISODate();
    const basketDefaultImplementationDate = plusBusinessDays(createDate(), 1).toISODate();

    const formDraft = useFormDraft(
      ref({
        reference: masterPortfolioTree.value?.portfolioTree.reference ?? DEFAULT_BASKET_REFERENCE,
        portfolioValue: masterPortfolioTree.value?.portfolioTree.portfolioValue ?? DEFAULT_BASKET_NOTIONAL,
        toCurrency: masterPortfolioTree.value?.portfolioTree.toCurrency ?? DEFAULT_BASKET_CURRENCY,
        name: masterPortfolioTree.value?.portfolioTree.name ?? DEFAULT_BASKET_NAME,
        originalMeasurementType:
          masterPortfolioTree.value?.portfolioTree.originalMeasurementType ?? DEFAULT_ORIGIN_MEASUREMENT_TYPE,
        returnType: masterPortfolioTree.value?.portfolioTree.returnType ?? DEFAULT_RETURN_TYPE,
        basketDividendTreatment:
          masterPortfolioTree.value?.portfolioTree.basketDividendTreatment ?? DEFAULT_BASKET_DIVIDEND_TREATMENT,
        basketImplementationDate:
          masterPortfolioTree.value?.portfolioTree.basketImplementationDate ?? basketDefaultImplementationDate,
        basketObservationDate:
          masterPortfolioTree.value?.portfolioTree.basketObservationDate ?? basketDefaultSelectionDate,
      }),
      [],
    );
    const form = formDraft.form();

    const originalMeasurementTypes = Object.values(MeasurementTypeConstants);
    const currencies = Object.values(Currency);
    const basketDividendTreatments = Object.values(CashReinvestmentTypeConstants);
    const returnTypes: ReturnTypeConstants[] = Object.values(ReturnTypeConstants);

    const getIconColorToShow = (compliantStatus: BasketCommentTypeConstants | null) => {
      // If the status is null, it means the basket is compliant. The reason being the api side doesn't have the enum compliant.
      if (!compliantStatus) return ComplianceStatusStyleConfigMap[BasketCommentTypeConstants.COMPLIANT].iconColor;
      return ComplianceStatusStyleConfigMap[compliantStatus].iconColor ?? '';
    };

    const getIconToShow = (compliantStatus: BasketCommentTypeConstants | null) => {
      // If the status is null, it means the basket is compliant. The reason being the api side doesn't have the enum compliant.
      if (!compliantStatus) return ComplianceStatusStyleConfigMap[BasketCommentTypeConstants.COMPLIANT].icon;
      return ComplianceStatusStyleConfigMap[compliantStatus].icon ?? '';
    };

    const { originalSlug } = usePortfolioTreeDraftIdentifiers();

    const { successToast } = useToasts();

    const savePortfolioTreeMutation = useSavePortfolioTree({});
    const calculateMutation = useCalculatePortfolioTree();

    const shouldShowConfirmationModal = ref(false);

    const isSubmitting = computed(
      () =>
        submitBasketMutation.isLoading.value ||
        calculateMutation.isLoading.value ||
        savePortfolioTreeMutation.isLoading.value,
    );

    const submitBasket = async () => {
      shouldShowConfirmationModal.value = false;
      //  Update master portfolio with the selected value
      if (masterPortfolioTree.value) {
        set(masterPortfolioTree.value, 'portfolioTree', {
          ...masterPortfolioTree.value.portfolioTree,
          ...form.value,
        });

        if (SaveChangesStore.customSavingPrompt) {
          const result = await SaveChangesStore.WaitForSaveChangesModalResponse();
          if (result === SaveChangesModalResponse.CANCEL) {
            return;
          }
        }

        // The draft gets saved inside the calculation mutation
        await calculateMutation.mutateAsync({ tree: masterPortfolioTree.value });

        // Ensure the draft is saved to the master portfolio before submitting the basket
        await savePortfolioTreeMutation.mutateAsync(masterPortfolioTree.value.slug);

        // As the submit basket endpoint uses the master portfolio, we need to pass the original slug instead of draft slug here.
        if (!originalSlug.value) return;
        await submitBasketMutation.mutateAsync({ originalSlug: originalSlug.value });
      }

      // close modals
      shown.value = false;
    };

    const submitBasketMutation = useSubmitBasket({
      onSuccess() {
        successToast(
          translate({
            path: 'MODALS.STATIC_DATA_MODAL.BASKET_SUCCESS_SUBMIT_TOAST',
          }),
        );
      },
    });

    // TODO: investigate why formDraft.reset is not working. For the Nov release, I will just reset the form manually.
    const resetModal = () => {
      form.value = {
        reference: masterPortfolioTree.value?.portfolioTree.reference ?? DEFAULT_BASKET_REFERENCE,
        portfolioValue: masterPortfolioTree.value?.portfolioTree.portfolioValue ?? DEFAULT_BASKET_NOTIONAL,
        toCurrency: masterPortfolioTree.value?.portfolioTree.toCurrency ?? DEFAULT_BASKET_CURRENCY,
        name: masterPortfolioTree.value?.portfolioTree.name ?? DEFAULT_BASKET_NAME,
        originalMeasurementType:
          masterPortfolioTree.value?.portfolioTree.originalMeasurementType ?? DEFAULT_ORIGIN_MEASUREMENT_TYPE,
        returnType: masterPortfolioTree.value?.portfolioTree.returnType ?? DEFAULT_RETURN_TYPE,
        basketDividendTreatment:
          masterPortfolioTree.value?.portfolioTree.basketDividendTreatment ?? DEFAULT_BASKET_DIVIDEND_TREATMENT,
        basketImplementationDate:
          masterPortfolioTree.value?.portfolioTree.basketImplementationDate ?? basketDefaultImplementationDate,
        // TODO: WAA-11286 basketObservationDate will be renamed to basketSelectionDate on api side,same applied to public api
        basketObservationDate:
          masterPortfolioTree.value?.portfolioTree.basketObservationDate ?? basketDefaultSelectionDate,
      };
    };

    /**
     *  To avoid the timezone issue, we only allow any date that is greater than the current date (on hold)
     */
    const isBasketValid = computed(() => {
      return (
        form.value.basketDividendTreatment &&
        form.value.name &&
        form.value.originalMeasurementType &&
        form.value.portfolioValue &&
        form.value.returnType &&
        form.value.toCurrency &&
        form.value.basketImplementationDate &&
        form.value.basketObservationDate &&
        isImplementationDateValid.value
      );
    });

    const getChangePrefix = (change: number) => {
      if (change > 0) return '+';
      return '';
    };

    const implementationDateDisabledFn = (_ymd: string, date: Date) => {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // Disable dates before the current date
      const weekday = date.getDay();
      return weekday === 0 || weekday === 6 || date < new Date();
    };

    const observationDateDisabledFn = (_ymd: string, date: Date) => {
      // Disable weekends (Sunday = `0`, Saturday = `6`)
      const weekday = date.getDay();
      return weekday === 0 || weekday === 6;
    };

    const isImplementationDateValid = computed(() => {
      if (!form.value.basketObservationDate || !form.value.basketImplementationDate) {
        return null; // Return null if either date is not set yet
      }

      const observationDate = createDate(form.value.basketObservationDate);
      const implementationDate = createDate(form.value.basketImplementationDate);

      return implementationDate > observationDate ? null : false;
    });

    const { user } = useUser();

    const tickerBase = computed(() => {
      return user.value?.bofaCustomBasketTickerBase ?? null;
    });

    const tickerValidState = computed(() => {
      // Don't check if the field is disabled
      if (shouldDisableRebalanceField.value) {
        return null;
      }
      // Don't check if no ticker base is defined
      if (!tickerBase.value) {
        return null;
      }
      // Allow empty ticker
      if (!form.value.reference) {
        return null;
      }
      // Pass if the ticker starts with the ticker base
      if (form.value.reference.startsWith(tickerBase.value)) {
        return null;
      }
      return false;
    });

    return {
      translate,
      shown,
      onCancel,
      fields,
      items,
      sortBy,
      sortDesc,
      form,
      currencies,
      basketDetailsData,
      masterPortfolioTree,
      originalMeasurementTypes,
      returnTypes,
      basketDividendTreatments,
      getIconToShow,
      getIconColorToShow,
      submitBasket,
      isBasketValid,
      shouldShowConfirmationModal,
      getColorClass,
      resetModal,
      getChangePrefix,
      isSubmitting,
      title,
      id,
      isEquityBasketPortfolio,
      isDraftBasket,
      numDecimalPoints,
      implementationDateDisabledFn,
      observationDateDisabledFn,
      shouldDisableRebalanceField,
      isImplementationDateValid,
      tickerBase,
      tickerValidState,
    };
  },
});
</script>
